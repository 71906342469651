import React from "react";
import { Announcement } from "@civicactions/data-catalog-components";
import Layout from '../../components/Layout';
import config from "../../assets/config";

const About = ({ path }) => (
    <Layout title="About">
        <div className={`dc-page ${config.container}`}>
            <h1>Το έργο και ο Φορέας</h1>
            <div className="dc-page-content row">
                <div class="col-md-9 col-sm-12">
                <p><strong>Το έργο</strong></p>

                <p>Το Εθνικό Κτηματολόγιο είναι ένα ενιαίο και διαρκώς ενημερωμένο σύστημα πληροφοριών που καταγράφει τις νομικές, τεχνικές και άλλες πρόσθετες πληροφορίες για τα ακίνητα και τα δικαιώματα πάνω σ΄αυτά, με την ευθύνη και την εγγύηση του Δημοσίου. 
                Η σύνταξη του αποσκοπεί στη δημιουργία ενός σύγχρονου, πλήρως αυτοματοποιημένου αρχείου ακίνητης ιδιοκτησίας, όλα τα στοιχεία του οποίου έχουν αποδεικτικό χαρακτήρα, εξασφαλίζοντας τη μεγαλύτερη δυνατή δημοσιότητα και ασφάλεια των συναλλαγών. </p>

                <p>Το Εθνικό Κτηματολόγιο:</p>

                <ol>
                <li>Καταγράφει με βάση το ακίνητο όλες τις πράξεις που δημιουργούν ή αλλάζουν δικαιώματα σε ακίνητα.</li>
                <li>Εγγυάται τις νομικές πληροφορίες που καταγράφει, καθώς η καταχώριση κάθε πράξης γίνεται μόνο μετά από ουσιαστικό έλεγχο νομιμότητας</li>
                <li>Καταγράφει και την γεωγραφική περιγραφή (μορφή, θέση και μέγεθος) του ακινήτου.</li>
                <li>Αποκαλύπτει και καταγράφει συστηματικά τη Δημόσια ακίνητη περιουσία</li>
                <li>Καταγράφει τα δικαιώματα από χρησικτησία η οποία, ιδίως στην περιφέρεια, αποτελεί τον συνηθέστερο ίσως τρόπο κτήσης κυριότητας λόγω του άτυπου των μεταβιβάσεων.</li>
                </ol>
                

                <p>Το Εθνικό Κτηματολόγιο εισάγει καινοτομίες που το καθιστούν ένα πραγματικά θεμελιώδες έργο για την Ελλάδα, που δημιουργεί πολύ σημαντικά οφέλη για τον πολίτη, την εθνική οικονομία και την προστασία του περιβάλλοντος:</p>

                <ol>
                <li>Προχωρά στην οριστική, χωρίς αμφισβητήσεις, καταγραφή και κατοχύρωση της ιδιοκτησίας των πολιτών</li>
                <li>Περιορίζει την γραφειοκρατία και γίνονται απλούστερες και ταχύτερες οι διαδικασίες μεταβίβασης της ακίνητης περιουσίας.</li>
                <li>Ενισχύει τη διαφάνεια και την ασφάλεια στις μεταβιβάσεις ακινήτων</li>
                <li>Αναβαθμίζει την αγορά ακινήτων και αυξάνει την αξία της ακίνητης ιδιοκτησίας με συνέπεια να προσελκύονται σημαντικότατες επενδύσεις</li>
                <li>Οριοθετεί αμετάκλητα και διασφαλίζει πλήρως τη δημόσια και δημοτική περιουσία</li>
                <li>Προστατεύει αποτελεσματικότερα το περιβάλλον. Η αμετάκλητη οριοθέτηση των δασών και του αιγιαλού θα αποτελέσει το σοβαρότερο εμπόδιο ενάντια στις καταπατήσεις και την αυθαιρεσία.</li>
                <li>Αποτελεί απαραίτητο εργαλείο για την ορθολογική οργάνωση και ανάπτυξη της χώρας.</li>
                </ol>
                

                <p><strong>Το νομικό πρόσωπο δημοσίου δικαίου «Ελληνικό Κτηματολόγιο»</strong></p>

                <p>Το νομικό πρόσωπο δημοσίου δικαίου με την επωνυμία «Ελληνικό Κτηματολόγιο», ιδρύθηκε με το ν. 4512/2018, αποτελεί μετεξέλιξη της «Εθνικό Κτηματολόγιο & Χαρτογράφηση Α.Ε», έχει έδρα την Αθήνα και εποπτευόταν από τον Υπουργό Περιβάλλοντος και Ενέργειας. Με το υπ΄ αριθμ. 3 Προεδρικό Διάταγμα «Μεταφορά υπηρεσιών και αρμοδιοτήτων μεταξύ Υπουργείων και μετονομασία Γενικών Γραμματειών» (ΦΕΚ τ Α΄/3/6.1.2021), η εποπτεία του νομικού προσώπου δημοσίου δικαίου με την επωνυμία «Ελληνικό Κτηματολόγιο Α.Ε.», το οποίο συνεστήθη με το άρθρο 1 του ν. 4512/2018 (Α’ 5), μεταφέρεται από τον Υπουργό Περιβάλλοντος και Ενέργειας στον Υπουργό Ψηφιακής Διακυβέρνησης (Άρθρο 1, παρ.2.).</p>

                <p>Σκοπός του Ελληνικού Κτηματολογίου είναι η διασφάλιση της αξιοπιστίας, δημοσιότητας και διαθεσιμότητας των χωρικών και νομικών δεδομένων που αφορούν την ακίνητη ιδιοκτησία και η διασφάλιση της δημόσιας πίστης και ασφάλειας των συναλλαγών, σε σχέση με τα δεδομένα αυτά.</p>

                <p>Ο σκοπός αυτός επιτυγχάνεται με την καταχώριση νομικών και τεχνικών πληροφοριών, για τον ακριβή καθορισμό της θέσης και των ορίων των ακινήτων και τη δημοσιότητα των εγγραπτέων δικαιωμάτων και βαρών μέσω της σύνταξης, τήρησης, ενημέρωσης και λειτουργίας του Εθνικού Κτηματολογίου.</p>

                <p>Στο σκοπό του επίσης περιλαμβάνεται και η γεωδαιτική και χαρτογραφική κάλυψη της χώρας, καθώς και η δημιουργία και τήρηση ψηφιακών γεωχωρικών δεδομένων. Συνεπώς το έργο της κτηματογράφησης όλης της χώρας και η οργάνωση και η λειτουργία του θεσμού του Κτηματολογίου αποτελούν ευθύνη του ΝΠΔΔ «Ελληνικό Κτηματολόγιο» το οποίο είναι αρμόδιο για:</p>

                <ul>
                <li>Την σύνταξη, τήρηση, ενημέρωση και λειτουργία του Εθνικού Κτηματολογίου.</li>
                <li>Την τήρηση, ενημέρωση και λειτουργία του συστήματος των υποθηκοφυλακείων στα Κτηματολογικά Γραφεία και τα Υποκαταστήματα του Φορέα, τα οποία θα συσταθούν σταδιακά.</li>
                <li>Την σύνταξη, ενημέρωση, τήρηση και αναθεώρηση βασικών και παράγωγων τοπογραφικών χαρτών και τοπογραφικών διαγραμμάτων.</li>
                <li>Τον σχεδιασμό, την ανάπτυξη, την οργάνωση, τη λειτουργία και την διαχείριση συστημάτων αναγκαίων για τη δημιουργία και διαχείριση γεωδαιτικού υλικού που σχετίζονται με το σκοπό του.</li>
                <li>Τον προγραμματισμό, την εκτέλεση και τον έλεγχο φωτογραμμετρικών και τηλεπισκοπικών εργασιών από τη λήψη αεροφωτογραφιών και δορυφορικών εικόνων έως και την τελική απόδοση, για την κάλυψη των αναγκών του Φορέα, του Ελληνικού Δημοσίου, των Οργανισμών Τοπικής Αυτοδιοίκησης και των Νομικών Προσώπων Δημοσίου Δικαίου.</li>
                <li>Την οργάνωση και τήρηση βάσεων ψηφιακών γεωχωρικών δεδομένων.​</li>
                </ul>
                

                <p>Στρατηγικό Σχέδιο 2021-2024 του Ελληνικού Κτηματολογίου</p>
                </div>
                <div className="col-md-3 col-sm-12">
                    <Announcement variation="info" heading="Note">
                    <p>Update this about page before publishing.</p>
                    </Announcement>
                </div>
            </div>
        </div>
    </Layout>
);

export default About;
